import axios from 'axios';
import jsCookie from 'js-cookie';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();
const AXIOS_TIMEOUT = 60000;

let user = JSON.parse(localStorage.getItem('user'));
let options = {
	baseURL: "https://backend.grabdata.org",
	// baseURL: "http://localhost:3100",
	withCredentials: true,
	timeout: AXIOS_TIMEOUT,
};
if (user && user.token && user) {
	options.headers = {
		'Authorization': `Bearer ${user.token}`,
	};
}

const axiosInstance = axios.create(options);

axiosInstance.interceptors.response.use(function (response) {
	return response;
}, function (error) {
	if (error.response && error.response.status === 401) {
		jsCookie.remove("login");
		jsCookie.remove("access");
		if (
			!(~window.location.href.indexOf('login')
				|| ~window.location.href.indexOf('reset-password')
				|| ~window.location.href.indexOf('forgot-password'))
		) {
			history.push("/login");
			window.location.href = "/login";
		}
	}
	return Promise.reject(error);
});

// Alter defaults after instance has been created
axiosInstance.defaults.headers.common['token'] = process.env.REACT_APP_SITE_TOKEN;


export default axiosInstance;
