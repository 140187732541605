import React, { createContext, useState, useEffect, useContext } from 'react';
import jsCookie from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { revalidateLogin } from '../store/actions/user.action.js';

const UserContext = createContext();


export function useUserProfile() {
	return useContext(UserContext);
}


export function UserContextProvider({ children }) {

	const [userProfile_c, setUserProfile_c] = useState({});
	const [isLoggedIn_c, setIsLoggedIn_c] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		const userLoginCookie = jsCookie.get('login');
		if (userLoginCookie)
			setIsLoggedIn_c(true);
		if (!userProfile_c) {
			const user = JSON.parse(localStorage.getItem('user'));
			if (user) {
				setUserProfile_c(user);
			}
		}
	}, []);

	function getIsLoggedIn_c() {
		return isLoggedIn_c;
	}

	function getUserProfile_c() {
		return Object.keys(userProfile_c).length ? userProfile_c : getUserProfile_fromLocalStorage();
	}

	function refreshProfile() {
		const user = JSON.parse(localStorage.getItem('user'));
		if (user) {
			setUserProfile_c(user);
		}
	}


	function checkIsLoggedIn_c() {
		const userLoginCookie = jsCookie.get('login');
		const userLocalStorage = getUserProfile_fromLocalStorage();
		if (userLoginCookie && userLocalStorage)
			return true;
		return false;
	}

	function getUserProfile_fromLocalStorage() {
		const user = JSON.parse(localStorage.getItem('user'));
		if (user) {
			return user;
		}
		return null;
	}

	function logoutUser_c(isRevalidating = false) {
		jsCookie.remove('login');
		jsCookie.remove('access');
		localStorage.clear();
		setIsLoggedIn_c(false);
		setUserProfile_c({});
		if (isRevalidating) {
			navigate('/login');
		}
		return true;
	}

	function loginUser_c(responseData, isRevalidating = false) {
		if (!responseData?.user)
			return false;
		jsCookie.set("login", "yes");
		localStorage.setItem('user', JSON.stringify(responseData?.user));
		setIsLoggedIn_c(true);
		setUserProfile_c(responseData?.user);
		if (isRevalidating) {
			navigate('/');
		}
		return true;
	}


	async function revalidateLogin_c() {
		const res = await revalidateLogin();
		if (res.status === 200) {
			loginUser_c(res.data.data, true);
		} else {
			logoutUser_c(true);
		}
	}


	return (
		<UserContext.Provider value={{
			getUserProfile_c,
			getIsLoggedIn_c,

			checkIsLoggedIn_c,
			logoutUser_c,

			loginUser_c,
			refreshProfile,
			revalidateLogin_c,
		}}>
			{children}
		</UserContext.Provider>
	);
};
