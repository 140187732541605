import React, { useEffect, lazy, Suspense } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import { createBrowserHistory } from 'history';
import { toast } from "react-toastify";

import SpinnerComponent from './commonComponents/Loader/Loader.jsx';
import { useUserProfile } from '../context/UserContextProvider.js';
import { FormContextProvider } from '../context/FormContextProvider.js';
import { EditFormContextProvider } from '../context/EditFormContextProvider.js';
import { logout } from '../store/actions/user.action.js';
import { useMediaQuery } from '../helpers/custom-hooks.js';

const PasswordForget = lazy(() => import("./LoginSignup/ForgotPassword.jsx"));
const CreateCity = lazy(() => import("./City/City.jsx"));
const ListCity = lazy(() => import("./City/ListCity.jsx"));
const CreateUser = lazy(() => import("./User/User.jsx"));
const UpdateUser = lazy(() => import("./User/UpdateUser.jsx"));
const ListUsers = lazy(() => import("./User/UserList.jsx"));
const Reports = lazy(() => import("./Reports/Reports.jsx"));
const FrequencyReport = lazy(() => import("./Reports/FrequencyReport.jsx"));
const MeanReport = lazy(() => import("./Reports/MeanReport.jsx"));
const PasswordReset = lazy(() => import("./LoginSignup/ResetPassword.jsx"));

const NotFound = lazy(() => import("./commonComponents/NotFound/NotFound.jsx"));
const Sidebar = lazy(() => import("./commonComponents/Sidebar/Sidebar.jsx"));
const Footer = lazy(() => import('./commonComponents/Footer/Footer.jsx'));
// const Header = lazy(() => import('./commonComponents/Header/Header'))
const Home = lazy(() => import('./Home/Home.jsx'));
const About = lazy(() => import('./About/About.jsx'));
const Contact = lazy(() => import('./Contact/Contact.jsx'));
const Login = lazy(() => import('./LoginSignup/Login.jsx'));
const Profile = lazy(() => import("./User/Profile.jsx"));
const ScrollTop = lazy(() => import("./commonComponents/Footer/ScrollTop.jsx"));
const Form = lazy(() => import("./Form/Form.jsx"));
const CaregiverDemographic = lazy(() => import("./Form/CaregiverDemographic.jsx"));
const ChildDemographic = lazy(() => import("./Form/ChildDemographic.jsx"));
const PatientScale = lazy(() => import("./Form/Section/Patient.jsx"));
const CaregiverScale = lazy(() => import("./Form/Section/Caregiver.jsx"));
const ChildScale = lazy(() => import("./Form/Section/Child.jsx"));
const EditForm = lazy(() => import("./Form/Edit/Edit.jsx"));
const EditCaregiverDemographic = lazy(() => import("./Form/Edit/EditCaregiverDemographic.jsx"));
const EditChildDemographic = lazy(() => import("./Form/Edit/EditChildDemographic.jsx"));
const EditPatientScale = lazy(() => import("./Form/Edit/Section/Patient.jsx"));
const EditCaregiverScale = lazy(() => import("./Form/Edit/Section/Caregiver.jsx"));
const EditChildScale = lazy(() => import("./Form/Edit/Section/Child.jsx"));

const protectedRoutes = [
	{
		path: "/",
		component: Home,
	},
	{
		path: "/about",
		component: About,
	},
	{
		path: "/contact",
		component: Contact,
	},
	{
		path: "/create-city",
		component: CreateCity,
	},
	{
		path: "/list-cities",
		component: ListCity,
	},
	{
		path: "/create-user",
		component: CreateUser,
	},
	{
		path: "/update-user",
		component: UpdateUser,
	},
	{
		path: "/list-users",
		component: ListUsers,
	},
	{
		path: "/full-report",
		component: Reports,
	},
	{
		path: "/frequency-report",
		component: FrequencyReport,
	},
	{
		path: "/mean-report",
		component: MeanReport,
	},
	{
		path: "/profile",
		component: Profile,
	},
	{
		path: '/form',
		component: Form,
	},
	{
		path: "/form-demo-caregiver",
		component: CaregiverDemographic,
	},
	{
		path: "/form-demo-child",
		component: ChildDemographic,
	},
	{
		path: '/form-scale-patient',
		component: PatientScale,
	},
	{
		path: '/form-scale-caregiver',
		component: CaregiverScale,
	},
	{
		path: '/form-scale-child',
		component: ChildScale,
	},
	{
		path: '/edit-form-demo-patient',
		component: EditForm,
	},
	{
		path: '/edit-form-demo-caregiver',
		component: EditCaregiverDemographic,
	},
	{
		path: '/edit-form-demo-child',
		component: EditChildDemographic,
	},
	{
		path: '/edit-form-scale-patient',
		component: EditPatientScale,
	},
	{
		path: '/edit-form-scale-caregiver',
		component: EditCaregiverScale,
	},
	{
		path: '/edit-form-scale-child',
		component: EditChildScale,
	},
	{
		path: "*",
		component: NotFound,
	},
];

const publicRoutes = [
	{
		path: "/login",
		component: Login,
	},
	{
		path: "/forgot-password",
		component: PasswordForget,
	},
	{
		path: "/reset-password",
		component: PasswordReset,
	},
	{
		path: "*",
		component: PrivateRoutes,
	},
];


function AppRoutes() {
	return (
		<>
			<Routes>
				{/* here were all public routes */}
				{publicRoutes.map((route, index) => (
					<Route key={index} path={route.path} element={
						<Suspense fallback={<SpinnerComponent />}>
							<route.component />
						</Suspense>
					} />
				))}
			</Routes>
		</>
	);
}

function PrivateRoutes() {
	const navigate = useNavigate();
	const { checkIsLoggedIn_c, logoutUser_c } = useUserProfile();
	const isLoggedIn = checkIsLoggedIn_c();
	const isTabletOrGreater = useMediaQuery('(min-width: 768px)');

	useEffect(() => {
		// this runs on every refresh of page or when user visits the protected route
		if (!isLoggedIn) {
			createBrowserHistory('/login');
			navigate("/login");
		}
	}, [isLoggedIn]);

	const handleLogout = async () => {
		const res = await logout();
		if (res.status === 200) {
			toast.update('Session Expired');
		}
		logoutUser_c();
		navigate("/login");
	};



	return (
		<>
			{!isLoggedIn ? null : (
				<>
					<Sidebar className="m-2" />

					<div className="row m-0">
						<Container className={isTabletOrGreater ? 'col-11' : 'col-12'}>
							<Routes>
								{/* Here were all protected routes */}
								{protectedRoutes.map((route, index) => {
									return (
										<Route
											key={index}
											path={route.path}
											element={
												<Suspense fallback={<SpinnerComponent />}>
													{isLoggedIn ? (route.path.includes('form')
														? <FormContextProvider>
															<EditFormContextProvider>
																<route.component />
															</EditFormContextProvider>
														</FormContextProvider>
														: <route.component />) : handleLogout()}
												</Suspense>
											}
										/>
									);
								})}
							</Routes>
						</Container>
					</div>
					<ScrollTop />
					<Footer />
				</>
			)}
		</>
	);
};

export default AppRoutes;

