import React, { createContext, useState, useContext, useEffect } from 'react';


const FormContext = createContext();

// a patient can have multiple children
// i also need to handle children context


export function useFormContext() {
	return useContext(FormContext);
}


export function FormContextProvider({ children }) {
	const [patientId, setPatientId] = useState(localStorage.getItem('patient_id') || 0);
	const [noOfChildren, setNoOfChildren] = useState(localStorage.getItem('no_of_children') || 0);
	const [formProgress, setFormProgress] = useState(localStorage.getItem('form_progress') || '');
	const NAVIGATE_TO = {
		patient: '/form',
		caregiver: '/form-demo-caregiver',
		child: '/form-demo-child',
		patientScale: '/form-scale-patient',
		caregiverScale: '/form-scale-caregiver',
		childScale: '/form-scale-child',
	};
	const FORM_PROGRESS = {
		patient: 'patient',
		caregiver: 'caregiver',
		child: 'child',
		patientScale: 'patientScale',
		caregiverScale: 'caregiverScale',
		childScale: 'childScale',
	};

	useEffect(() => {

	}, []);


	// helpers
	function getNavigateTo_c() {
		return NAVIGATE_TO;
	}
	function getFormProgress_c() {
		return FORM_PROGRESS;
	}

	// patient
	function setPatientId_c(id) {
		setPatientId(Number(id));
		localStorage.setItem('patient_id', Number(id));
		return true;
	}
	function getPatientId_c() {
		if (patientId)
			return patientId;
		const patient_id = localStorage.getItem('patient_id');
		if (patient_id)
			return Number(patient_id);
		return null;
	}

	// child
	function getNoOfChildren_c() {
		if (noOfChildren)
			return Number(noOfChildren);
		const no_of_children = localStorage.getItem('no_of_children');
		if (no_of_children)
			return Number(no_of_children);
		return null;
	}
	function setNoOfChildren_c(no) {
		setNoOfChildren(Number(no));
		localStorage.setItem('no_of_children', Number(no));
		return true;
	}

	// form
	function _getFormProgress() {
		if (formProgress) {
			return formProgress;
		}
		const progress = localStorage.getItem('form_progress');
		if (progress)
			return progress;
		return null;
	}
	function setFormProgress_c(progress) {
		// progress that is to be filled
		setFormProgress(progress);
		localStorage.setItem('form_progress', progress);
	}
	function checkFormProgress_c(progressToCheck) {

		// @return {boolean, string}
		// boolean: true if allowed to navigate to next page
		// string: next page to navigate to

		if (getPatientId_c()) {
			const form_progress = _getFormProgress();

			if (!form_progress) {
				resetAll_c();
				return [true, NAVIGATE_TO.patient];
			}

			if (progressToCheck === form_progress) {
				if (progressToCheck === FORM_PROGRESS.patient) {
					resetAll_c();
				}
				return [false, form_progress];
			}

			return [true, NAVIGATE_TO[form_progress]];

		}
		if (progressToCheck === FORM_PROGRESS.patient) {
			return [false, NAVIGATE_TO.patient];
		}
		resetAll_c();
		return [true, NAVIGATE_TO.patient];
	}

	function log() {
		console.log('Log');
		console.log({ patientId, formProgress });
		console.log(localStorage.getItem('patient_id'));
		console.log(localStorage.getItem('form_progress'));
		console.log(localStorage.getItem('no_of_children'));
		console.log(localStorage.getItem('child_being_filled'));
	}

	function resetAll_c() {
		setPatientId(0);
		setFormProgress('');
		setNoOfChildren(0);
		localStorage.removeItem('patient_id');
		localStorage.removeItem('form_progress');
		localStorage.removeItem('no_of_children');
		localStorage.removeItem('child_being_filled');
	}

	function setCookieAndLocalStorage_c() {
		setPatientId_c(1);
		setFormProgress_c(FORM_PROGRESS.caregiverScale);
	}
	function resetCookieAndLocalStorage() {
		resetAll_c();
	}

	return (
		<FormContext.Provider value={{
			setPatientId_c,
			getPatientId_c,

			setNoOfChildren_c,
			getNoOfChildren_c,

			setFormProgress_c,
			checkFormProgress_c,

			getNavigateTo_c,
			getFormProgress_c,
			resetAll_c,

			log,
			setCookieAndLocalStorage_c,
			resetCookieAndLocalStorage,
		}}>
			{children}
		</FormContext.Provider>
	);
};
