import React, { createContext, useState, useContext, useEffect } from 'react';


const EditFormContext = createContext();

// a patient can have multiple children
// i also need to handle children context


export function useEditFormContext() {
	return useContext(EditFormContext);
}


export function EditFormContextProvider({ children }) {
	const [editPatientId, setEditPatientId] = useState(null);
	const NAVIGATE_TO = {
		home: '/',
		patient: '/edit-form-demo-patient',
		caregiver: '/edit-form-demo-caregiver',
		child: '/edit-form-demo-child',
		patientScale: '/edit-form-scale-patient',
		caregiverScale: '/edit-form-scale-caregiver',
		childScale: '/edit-form-scale-child',
	};


	useEffect(() => {
		if (!editPatientId) {
			setEditPatientId(null);
		}
	}, []);


	function getEditNavigateTo_c() {
		return NAVIGATE_TO;
	}

	function setEditPatientId_c(id) {
		if (!id) return false;
		setEditPatientId(Number(id));
		return true;
	}
	function getEditPatientId_c() {
		return editPatientId;
	}

	function resetAllEdit_c() {
		setEditPatientId_c(null);
	}

	return (
		<EditFormContext.Provider value={{
			setEditPatientId_c,
			getEditPatientId_c,
			getEditNavigateTo_c,
			resetAllEdit_c,
		}}>
			{children}
		</EditFormContext.Provider>
	);
};
