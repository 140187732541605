import User from "../services/user/user";
import actionErrorHandler from '../../helpers/actionErrorHandler.js';

export const createUser = (form) => {
	return User.createUser(form)
		.then((response) => {
			return {
				status: response.status,
				data: response.data,
				message: "Request Success",
			};
		})
		.catch((e) => {
			return actionErrorHandler(e);
		});
};

export const updateUser = (form, id) => {
	return User.updateUser(form, id)
		.then((response) => {
			return {
				status: response.status,
				data: response.data,
				message: "user Updated successfully",
			};
		})
		.catch((e) => {
			return actionErrorHandler(e);
		});
};

export const listUser = (params) => {
	return User.listUser(params)
		.then((response) => {
			return {
				status: response.status,
				data: response.data,
				message: "Request Success",
			};
		})
		.catch((e) => {
			return actionErrorHandler(e);
		});
};

export const deleteUser = (id) => {
	return User.deleteUser(id)
		.then((response) => {
			return {
				status: response.status,
				data: response.data,
				message: "Request Success",
			};
		})
		.catch((e) => {
			return actionErrorHandler(e);
		});
};

export const allUsers = (form) => {
	return User.allUsers(form)
		.then((response) => {
			return {
				status: response.status,
				data: response.data,
				message: "success",
			};
		})
		.catch((e) => {
			return actionErrorHandler(e);
		});
};

export const getUserProfile = (getID) => {
	return User.getUserProfile(getID)
		.then((response) => {
			return {
				status: response.status,
				data: response.data,
				message: "Request Success",
			};
		})
		.catch((e) => {
			return actionErrorHandler(e);
		});
};

export const forgotUserPassword = (email) => {
	return User.forgotUserPassword(email)
		.then((response) => {
			return {
				status: response.status,
				data: response.data,
				message: "Check you Email to Reset Password",
			};
		})
		.catch((e) => {
			return actionErrorHandler(e);
		});
};

export const resetUserPassword = (form) => {
	return User.resetUserPassword(form)
		.then((response) => {
			return {
				status: response.status,
				data: response.data,
				message: response.message,
			};
		})
		.catch((e) => {
			return actionErrorHandler(e);
		});
};

export const changePassword = (form) => {
	return User.changePassword(form)
		.then((response) => {
			return {
				status: response.status,
				data: response.data,
				message: response.message,
			};
		})
		.catch((e) => {
			return actionErrorHandler(e);
		});
};

export const loginUser = (form) => {
	return User.loginUser(form)
		.then((response) => {
			return {
				status: response.status,
				data: response.data,
				message: "success",
			};
		})
		.catch((e) => {
			return actionErrorHandler(e);
		});
};

export const logout = () => {
	return User.logout()
		.then((response) => {
			return { status: response.status, data: response, message: "success" };
		})
		.catch((e) => {
			return actionErrorHandler(e);
		});
};

export const revalidateLogin = () => {
	return User.revalidateLogin()
		.then((response) => {
			return { status: response.status, data: response, message: "User Revalidated Successfully!" };
		})
		.catch((e) => {
			return actionErrorHandler(e);
		});
};