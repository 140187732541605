import axiosInstance from '../../../utils/axios.middleware.js';

class User {

	static createUser = (form) => {

		let apiPath = '/api/user';
		return axiosInstance.post(apiPath, {
			...form
		});

	};


	static listUser = (params) => {

		let apiPath = `/api/user/list-users?_pageNo=${params?._pageNo}&_count=${params?._count}`;
		return axiosInstance.get(apiPath).then(response => { return response; });

	};


	static deleteUser = (id) => {
		let apiPath = '/api/user/' + parseInt(id);
		return axiosInstance.delete(apiPath)
			.then(response => { return response; });
	};


	static updateUser = (form, id) => {
		let apiPath = 'api/user/' + Number(id);
		return axiosInstance.put(apiPath, {
			...form
		})
			.then(response => { return response; });

	};


	static getUserProfile = (getID) => {

		let apiPath = '/api/user/' + Number(getID);
		return axiosInstance.get(apiPath).then(response => { return response; });

	};


	static forgotUserPassword = (email) => {

		let apiPath = '/api/auth/forgot-password';
		return axiosInstance.post(apiPath, email);

	};


	static resetUserPassword = (form) => {

		let apiPath = '/api/auth/reset-password';
		return axiosInstance.post(apiPath, {
			...form
		});

	};


	static changePassword = (form) => {

		let apiPath = '/api/auth/change-password';
		return axiosInstance.put(apiPath, {
			...form
		});

	};


	static loginUser = (form) => {

		let apiPath = '/api/auth/login';
		return axiosInstance.post(apiPath, {
			...form
		})
			.then(response => { return response; });

	};


	static logout = () => {

		let apiPath = '/api/auth/logout';
		return axiosInstance.post(apiPath)
			.then(response => { return response; });

	};


	static revalidateLogin = () => {

		let apiPath = '/api/auth/revalidate-login';
		return axiosInstance.post(apiPath)
			.then(response => { return response; });

	};


}

export default User;