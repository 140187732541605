import React from "react";

import AppRoutes from "./AppRoutes";
import { UserContextProvider } from '../context/UserContextProvider.js';

import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./commonComponents/css/styles.css";
import "./commonComponents/css/styles.bundle.css";
import "./commonComponents/css/plugins.bundle.css";


function App() {

	return (
		<>
			<UserContextProvider>
				< AppRoutes />
			</UserContextProvider>
		</>
	);
}


export default App;
