const REQUEST_TIMEOUT_ERROR_MESSAGE = { message: "Request Timeout" };
const NETWORK_ERROR_MESSAGE = { message: "Network Error" };
const GENRAL_ERROR_MESSAGE = { message: "Request Failed" };
const REQUEST_TIMEOUT_ERROR_CODE = "ECONNABORTED";
const NETWORK_ERROR_CODE = "ERR_NETWORK";

export default function handleError(e) {
	let message = { message: GENRAL_ERROR_MESSAGE };
	if (e.response?.data?.message) {
		message = e.response.data;
	} else {
		message = e.code === REQUEST_TIMEOUT_ERROR_CODE ? REQUEST_TIMEOUT_ERROR_MESSAGE : e.code === NETWORK_ERROR_CODE ? NETWORK_ERROR_MESSAGE : GENRAL_ERROR_MESSAGE;
	}

	return {
		status: e.response?.status || 504,
		message
	};
};