import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

const SpinnerComponent = ({ text, vh100 }) =>
(
	<div className={`d-flex align-items-center justify-content-center ${vh100 ? 'vh-100' : ''}`}>
		<div className="text-center">
			<Button variant="primary" disabled>
				<Spinner
					as="span"
					animation="grow"
					size="sm"
					role="status"
					aria-hidden="true"
				/>
				{text ? text : "Loading..."}
			</Button>
		</div>
	</div>
);


export default SpinnerComponent;
